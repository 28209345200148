<template>
  <div>
    <v-row justify="end">
      <template>
        <div class="my-2">
          <v-btn class="btn-primary" fab small dark @click="OpenModalAdd()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </template>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="branches"
        :items-per-page="15"
        class="elevation-1"
        :loading="TableLoading"
        :disabled="TableLoading"
        loading-text="Loading... Please wait"
    >
      <!--Action -->
      <template v-slot:item.image="{item}">
        <v-avatar
            size="36px"
        >
          <img v-if="item.image" :src="item.image"/>
        </v-avatar>
      </template>
      <template v-slot:item.location="{item}">
        <div>{{ item.village }} {{ item.district }}</div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="OpenModalEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item.id)"> mdi-delete</v-icon>
      </template>
    </v-data-table>

    <!--Delete Modal-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Add Branch</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col>
                    <div class="field">
                      <div class="file is-large is-boxed">
                        <label class="file-label">
                          <input
                              @change="onFileChange"
                              class="file-input input-file-image"
                              type="file"
                              name="image"
                              accept="image/*"
                              ref="image"
                              hidden
                          />
                          <span class="file-cta">
                            <span class="file-icon">
                              <v-icon
                                  style="
                                  font-size: 60px !important;
                                  color: #719aff;
                                  cursor: pointer;
                                "
                                  class="fas fa-cloud-upload"
                              >mdi-cloud-upload</v-icon
                              >
                            </span>
                            <span
                                class="file-label"
                                style="
                                margin-top: 10px;
                                text-transform: uppercase;
                                padding-top: 20px;
                              "
                            >
                              Choose Profile
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p class="errors">
                      {{ server_errors.imageFile }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="mt-5" v-if="image">
                    <v-avatar class="avatar rounded" size="94px">
                      <img :src="imageUrl" alt=""/>
                    </v-avatar>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Name*"
                        required
                        v-model="branch.name"
                        :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Phone*"
                        required
                        v-model="branch.phone"
                        :rules="phoneRules"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.phone }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Email*"
                        required
                        v-model="branch.email"
                        :rules="emailRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.email }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                        dense
                        required
                        v-model="selectedDistrict"
                        :items="listDistricts"
                        item-text="name"
                        item-value="id"
                        label="ເລືອກເມືອງ *"
                        :rules="ruleDistrict"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                        dense
                        required
                        v-model="selectedVillage.id"
                        :items="listVillages"
                        item-text="name"
                        item-value="id"
                        label="ບ້ານ *"
                        :rules="ruleVillage"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ຈຸດສັງເກດ"
                        type="text"
                        v-model="branch.address"
                        required
                        @keyup.enter="AddBranch"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols>
                    <v-select
                        dense
                        required
                        v-model="selectedType"
                        :items="listType"
                        item-text="name"
                        item-value="key"
                        label="ປະເພດ *"
                        :rules="ruleType"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-text-field
                        label="Latitude"
                        v-model="center.lat"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="Longitude"
                        v-model="center.lng"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="mb-4">
                    <GmapMap
                        :center="center"
                        :zoom="16"
                        style="width: 100%; height: 450px"
                        :disableDefaultUI="true"
                    >
                      <GmapMarker
                          :key="index"
                          v-for="(m, index) in markers"
                          :position="m.position"
                          @click="center = m.position"
                          :draggable="true"
                          @dragend="onLocation"
                          :icon="markerOptions"
                          :animation="2"
                          ref="markers"
                      />
                    </GmapMap>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalAdd()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="AddBranch()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd>

    <!--Edit Modal-->

    <ModalEdit>
      <template @close="close" v-slot="">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Branch</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col>
                    <div class="field">
                      <div class="file is-large is-boxed">
                        <label class="file-label">
                          <input
                              @change="onFileChange"
                              class="file-input input-file-image"
                              type="file"
                              name="image"
                              accept="image/*"
                              ref="image"
                              hidden
                          />
                          <span class="file-cta">
                            <span class="file-icon">
                              <v-icon
                                  style="
                                  font-size: 60px !important;
                                  color: #719aff;
                                  cursor: pointer;
                                "
                                  class="fas fa-cloud-upload"
                              >mdi-cloud-upload</v-icon
                              >
                            </span>
                            <span
                                class="file-label"
                                style="
                                margin-top: 10px;
                                text-transform: uppercase;
                                padding-top: 20px;
                              "
                            >
                              Choose Profile
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="server_errors.imageFile">
                  <v-col>
                    <p class="errors">
                      {{ server_errors.imageFile }}
                    </p>
                  </v-col>
                </v-row>
                  <v-row>
                    <v-col class="mt-5" v-if="image">
                      <v-avatar class="avatar rounded" size="94px" v-if="image">
                        <img :src="imageUrl" alt=""/>
                      </v-avatar>
                    </v-col>
                    <v-col class="mt-5" v-else>
                      <v-avatar
                          class="avatar rounded mr-6"
                          size="94px"
                      >
                        <img v-if="branch_edit.image" :src="branch_edit.image"/>
                      </v-avatar>
                    </v-col>
                  </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Name*"
                        required
                        v-model="branch_edit.name"
                        :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Phone*"
                        required
                        v-model="branch_edit.phone"
                        :rules="phoneRules"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.phone }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Email*"
                        required
                        v-model="branch_edit.email"
                        :rules="emailRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.email }}
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                        dense
                        required
                        v-model="branch_edit.district_id"
                        :items="listDistricts"
                        item-text="name"
                        item-value="id"
                        label="ເລືອກເມືອງ *"
                        :rules="ruleDistrict"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                        dense
                        required
                        v-model="branch_edit.villageID"
                        :items="listVillages"
                        item-text="name"
                        item-value="id"
                        label="ບ້ານ *"
                        :rules="ruleVillage"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ຈຸດສັງເກດ"
                        type="text"
                        v-model="branch_edit.address"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols>
                    <v-select
                        dense
                        required
                        v-model="branch_edit.type"
                        :items="listType"
                        item-text="name"
                        item-value="key"
                        label="ປະເພດ *"
                        :rules="ruleType"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols>
                    <v-checkbox v-model="branch_edit.show"
                                label="ສະແດງໃນແອັບ"
                                row>
                    </v-checkbox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-text-field
                        label="Latitude"
                        v-model="branch_edit.lat"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="Longitude"
                        v-model="branch_edit.lng"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="mb-4">
                    <GmapMap
                        :center="getCenter()"
                        :zoom="16"
                        style="width: 100%; height: 450px"
                        :disableDefaultUI="true"
                    >
                      <GmapMarker
                          :position="getMarkers(branch_edit)"
                          :draggable="true"
                          @dragend="onLocation"
                          :icon="markerOptions"
                          :animation="2"
                          ref="markers"
                      />
                    </GmapMap>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalEdit()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="UpdateBranch()"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalEdit>

    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete()"
          >Cancel
          </v-btn
          >
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="deleteItemConfirm()"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
    <!-- Toast-->
  </div>
</template>

<script>
import manage from "../../../mixins/branch/manage";

export default {
  mixins: [manage],
  metaInfo() {
    return {
      title: `ຂໍ້ມູນສາຂາ`,
      meta: [
        { name: 'description', content: 'ຂໍ້ມູນສາຂາ'},
      ]
    }
  },
};
</script>

<style>
@import "../../../../public/scss/main.scss";
</style>