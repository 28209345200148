//import Validate from "@/plugins/Validation/Branch/branch"
export default {
    data() {
        return {
            loading: false,
            TableLoading: false,
            show:0,
            branchID: '',
            server_errors: {
                email: '',
                phone: '',
                imageFile: '',
            },
            image: "",
            imageUrl: "",
            branch: {},
            branch_edit: {},
            branches: [],
            selectedDistrict: "",
            selectedVillage: "",
            listDistricts: [],
            listVillages: [],
            listType: [
                {
                    id: 1,
                    name: "ສາຂາຫຼັກ",
                    key: "branch"
                } ,
                {
                    id: 2,
                    name: "ສາຂາຕົວແທນ",
                    key: "franchise"
                }
            ],
            selectedType:"",


            //Location
            center: {
                lat: 18.1189434,
                lng: 102.290218,
            },
            markers: [],
            currentPlace: null,
            markerOptions: {
                // eslint-disable-next-line global-require
                url: require('@coms/../../src/assets/vari_pin.png'),
                size: {
                    width: 35,
                    height: 55,
                    f: 'px',
                    b: 'px',
                },
                scaledSize: {
                    width: 35,
                    height: 55,
                    f: 'px',
                    b: 'px',
                },
            },

            headers: [
                {
                    text: "ລະຫັດ",
                    align: "start",
                    sortable: false,
                    value: "prefix",
                },
                {
                    text: "ຊື່ສາຂາ",
                    align: "start",
                    sortable: false,
                    value: "name",
                },
                {text: "ເບີໂທຕິດຕໍ່", value: "phone"},
                {text: "ອີເມວ", value: "email"},
                {text: "ທິ່ຕັ້ງ", value: "location",width: "140"},
                {text: "ຈຸດສັງເກດ", value: "address",width: "120"},
                {text: "ສາຂາ", value: "type", width: "120"},
                {text: "ສະແດງ", value: "show", width: "100", align: "center"},
                {
                    text: "",
                    align: "start",
                    sortable: false,
                    value: "image",
                    width: "80"
                },
                {text: "Actions", value: "actions", sortable: false},
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length >= 2) || 'Name must be less than 2 characters',
            ],
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            ],
            phoneRules: [
                (v) => !!v || "Phone is required",
                v => (v && v.length >= 4 && v.length <= 11) || 'Phone number must be  4 - 11 numbers',
            ],
            remarkRules: [
                v => !!v || 'Name is required',
                v => (v && v.length >= 2) || 'Name must be less than 2 characters',
            ],
            ruleType: [(v) => !!v || "Type is required"],
            ruleDistrict: [(v) => !!v || "District is required"],
            ruleVillage: [(v) => !!v || "Village is required"],

            toast: {
                value: true,
                color: 'success',
                msg: 'Success'
            },
            toast_error: {
                value: true,
                color: 'error',
                msg: 'Something when wrong!'
            }
        }
    },
    methods: {
        onFileChange(e) {
            let input = e.target;
            let file = e.target.files[0];
            this.image = input.files[0];
            this.imageUrl = URL.createObjectURL(file);
            this.server_errors.imageFile = '';
            let formData = new FormData();
            formData.append("imageFile", this.image);
            this.$axios.post('/upload-image', formData)
                .then(res => {
                    if (res.status == 200) {
                        this.image = res.data.fileName;
                    }
                }).catch(error => {
                this.image = '';
                if (error.response.status == 422) {
                    const obj = error.response.data.errors;
                    for (let [key, user] of Object.entries(obj)) {
                        this.server_errors[key] = user[0];
                    }
                }
            });

        },
        OpenModalAdd() {
            this.$store.commit("modalAdd_State", true);
        },
        AddBranch() {
            if (this.$refs.form.validate() == true) {
                this.Submit();
            }
        },
        Submit() {
            this.loading = true;
            const data = {
                name: this.branch.name,
                phone: this.branch.phone,
                email: this.branch.email,
                address: this.branch.address,
                village_id: this.selectedVillage.id,
                type: this.selectedType,
                image: this.image,
                lat: this.center.lat,
                lng: this.center.lng
            }
            this.$admin.post('register-branch', data)
                .then(res => {
                    if (res.data.success == true) {
                        setTimeout(() => {
                            this.loading = false;
                            this.CloseModalAdd();
                            this.branch = {};
                            this.getData();
                            this.reset();
                            this.$store.commit("Toast_State", this.toast);
                        }, 1000);
                    }
                }).catch(error => {
                this.loading = false;
                this.$store.commit("Toast_State", this.toast_error);
                if (error.response.status == 422) {
                    let obj = error.response.data.errors;
                    for (let [key, branch] of Object.entries(obj)) {
                        this.server_errors[key] = branch[0];
                    }
                }
            });
        },
        CloseModalAdd() {
            this.branch = {};
            this.imageUrl="";
            this.image = "";
            this.reset();
            this.getData();
            this.$store.commit("modalAdd_State", false);
        },
        OpenModalEdit(item) {
            this.branch_edit = item;
            this.$store.commit("modalEdit_State", true);
        },

        UpdateBranch() {
            const data = {
                name: this.branch_edit.name,
                phone: this.branch_edit.phone,
                email: this.branch_edit.email,
                address: this.branch_edit.address,
                village_id: this.branch_edit.villageID,
                type: this.branch_edit.type,
                image: this.image,
                lat: this.branch_edit.lat,
                lng: this.branch_edit.lng,
                show: this.branch_edit.show,
            }
            if (this.$refs.form.validate() == true) {
                this.loading = true;
                this.$admin.put('edit-branch/' + this.branch_edit.id, data)
                    .then(res => {
                        if (res.data.success == true) {
                            setTimeout(() => {
                                this.loading = false;
                                this.CloseModalEdit();
                                this.branch_edit = {};
                                this.getData();
                                this.reset();
                                this.$store.commit("Toast_State", this.toast);
                            }, 300);
                        }
                    }).catch(error => {
                    this.loading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                    this.getData();
                    if (error.response.status == 422) {
                        let obj = error.response.data.errors;
                        for (let [key, branch] of Object.entries(obj)) {
                            this.server_errors[key] = branch[0];
                        }
                    }
                });
            }
        },

        CloseModalEdit() {
            this.branch_edit = {};
            this.imageUrl="";
            this.image = "";
            this.getData();
            this.reset();
            this.$store.commit("modalEdit_State", false);
        },

        getData() {
            this.TableLoading = true;
            this.$admin.get('list-branch').then(res => {
                setTimeout(() => {
                    this.branches = res.data.branches;
                    this.TableLoading = false;
                }, 100);
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });

            this.$admin
                .get("list-address")
                .then((res) => {
                     this.listVillages = res.data.listVillages;
                    this.listDistricts = res.data.districts;
                    this.filterVillages = res.data.listVillages;
                })
                .catch(() => {
                    this.$store.commit("Toast_State", this.toast_error);
                });
        },
        FilterVillages(districtId) {
            const result_checking = this.filterVillages.filter((item) => {
                return item.district_id == districtId;
            });
            this.listVillages = result_checking;
            this.selectedVillage = {...this.listVillages[0]};
        },
        RefreshVillage() {
            this.$admin
                .get("list-address")
                .then((res) => {
                    this.filterVillages = res.data.listVillages;
                    this.TableLoading = false;
                    this.FilterVillages(this.selectedDistrict);
                })
                .catch(() => {
                    this.TableLoading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                });
        },
        closeDelete() {
            this.$store.commit("modalDelete_State", false);
        },
        deleteItem(id) {
            this.branchID = id;
            this.$store.commit("modalDelete_State", true);
        },
        deleteItemConfirm() {
            this.loading = true;
            this.$admin.delete('delete-branch/' + this.branchID).then(res => {
                if (res.data.success == true) {
                    setTimeout(() => {
                        this.getData();
                        this.$store.commit("modalDelete_State", false);
                        this.loading = false;
                        this.$store.commit("Toast_State", this.toast);
                    }, 1000);
                }
            }).catch(() => {
                this.getData();
                this.$store.commit("modalDelete_State", false);
                this.loading = true;
                this.$store.commit("Toast_State", this.toast_error);
            })
        },
        reset() {
            this.$refs.form.reset();
        },

        //Set Googlemap Api
        createNewAddressName() {
            const CUSTOMIZE = '#CUSTOM ADDRESS:';
            return this.isCreate ? this.currentAddress : `${CUSTOMIZE} ${this.center.lat}, ${this.center.lng}`;
        },
        onLocation(evt) {
            this.center.lat = evt.latLng.lat();
            this.center.lng = evt.latLng.lng();
            this.address = this.createNewAddressName();
            this.branch_edit.lat = this.center.lat;
            this.branch_edit.lng = this.center.lng;
        },
        setPlace(place) {
            this.currentPlace = place;
            this.placeMarker();
        },
        placeMarker() {
            this.markers = [];
            this.places = [];
            if (this.currentPlace) {
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng(),
                };
                this.markers.push({position: marker});
                this.center = marker;
                this.animateMarker();
            } else {
                const marker = {
                    lat: this.center.lat,
                    lng: this.center.lng,
                };
                this.markers.push({position: marker});
                this.animateMarker();
            }
            // set address
            if (this.$refs.searchInput) {
                this.address = this.$refs.searchInput.$el.value;
            } else {
                // this.address = this.currentPlace.formatted_address;
            }
            this.onDataChange();
        },
        animateMarker() {
            this.$nextTick(() => {
                // const obj = this.$refs.markers[0].$markerObject;
                // if (obj) {
                //     obj.setAnimation(1);
                //     clearTimeout(this.timer);
                //     this.timer = setTimeout(() => {
                //         obj.setAnimation(null);
                //     }, 800);
                // }
            });
        },
        geolocate() {
            navigator.geolocation.getCurrentPosition((position) => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                this.placeMarker();
            });

        },
        onDataChange() {
            this.$emit('onDataChange', {
                address: this.address,
                position: this.center,
            });
            // (this.center);
        },
        onSave() {
            this.$emit('onSave', {
                address: (this.address || this.currentAddress || 'Unnamed Location'),
                position: this.center,
                isCreate: this.isCreate,
            });
        },

        getCenter() {
            if (this.branch_edit.lat) {
                const latlng = {
                    lat: parseFloat(this.branch_edit.lat),
                    lng: parseFloat(this.branch_edit.lng),
                };
                return latlng;
            } else {
                return this.center;
            }
        },
        getMarkers(data) {
            if (data.lat != null) {
                return {
                    lat: parseFloat(data.lat),
                    lng: parseFloat(data.lng),
                };
            } else {
                return this.center;
            }
        },

    },
    watch: {
        selectedDistrict: function (dist_id) {
            this.FilterVillages(dist_id);
        },
        'branch.email': function () {
            this.server_errors.email = '';
        },
        'branch.phone': function () {
            this.server_errors.phone = '';
        },
        'branch_edit.email': function () {
            this.server_errors.email = '';
        },
        'branch_edit.phone': function () {
            this.server_errors.phone = '';
        },
    },
    created() {
        this.getData();
    },
    mounted() {
        this.geolocate();
    }
}
